<template>
  <v-overlay class='user-settings-overlay' :model-value="overlay" :persistent="true">
    <v-progress-circular
          indeterminate
          color="accent"
          :size="60"
    ></v-progress-circular> 
  </v-overlay>
  <v-main style="padding-top:50px;">
    <v-container v-show="pageLoaded">
      <!-- <p style="font-weight:bold;" v-if="isInDialog">Welcome!</p>  -->
      <p style="margin-top: 30px;margin-bottom: 10px;">Email preferences</p>
      <v-checkbox-btn
          v-model="newsletter"
          color="primary"
        >
          <template v-slot:label>
            Yes please send me news and feature updates
          </template>
      </v-checkbox-btn>
      <v-radio-group v-model="paddleAddedNotify">
      <p style="margin-top: 30px;margin-bottom: 10px;">Notify me when paddles are added to the map: </p>
      <v-radio color="primary" label="At any location, I want to know about all the new routes." value="any"></v-radio>
      <v-radio color="primary" label="Don't notify me" value="none"></v-radio>
      <v-radio color="primary" value="withinDistance">
        <template v-slot:label>
          Within  <v-select
            v-model="distance"
            :items="distances"
            style="width:90px;"
            :density="'compact'"
            outlined
          ></v-select>  miles of 
 
        </template>
      </v-radio> 
      <div id="geocoder"></div>
      <v-list-item v-if="errorMsg" class="error-msg">{{ errorMsg }}</v-list-item>
    </v-radio-group>
    <v-btn
      color="#FF4F00"
      class="save-user-settings-btn"
      dark
      variant="flat"
      @click="saveSettings()"
      >
      Save
    </v-btn> 
    <span v-if="isInDialog" class="settings-location-hint">Settings can be changed at any time and are found under the user icon</span>
    </v-container>
    <div v-show="!pageLoaded" class="spinner-container">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="60"
      ></v-progress-circular>
    </div>
  </v-main>
</template>

<script>
  import NODE_API from '../utils/api';
  import constants from '../utils/constants'; 
  import NavBar from '../components/NavBar.vue';
  import { Auth } from 'aws-amplify';
  import ConfirmDialog from '../components/ConfirmDialog.vue';
  import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
  import { nextTick } from 'vue';
  const API_KEY = process.env.VUE_APP_API_KEY;
  import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
  import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

  export default {
    name: 'UserSettings',
    components: {
      NavBar,
      ConfirmDialog
    },
    props: {
      userId: String,
      isInDialog: Boolean
    },
    emits: ['close'],
    async created() {
      this.user = await Auth.currentAuthenticatedUser(); 
      if (!this.user) {
        this.$router.push('/'); 
      } else {

        try {
          let response = await NODE_API.get(`${constants.amazonGatewayUrl}/userMetadata?username=${this.user.username}`);
          this.userMetadata = response.data.ret;     
          
          if (!this.userMetadata) {
            //this can happen if the user object wasn't added to mongo - 
            //the post confirmation handler didn't fire. 
            //which could happen for those users who we manually imported, and reset passwords for
          } else {
            this.newsletter = this.userMetadata.preferences.subscribe; 
            this.paddleAddedNotify = this.userMetadata.preferences.notifications.onPaddleAdd; 
            if (this.userMetadata.preferences.notifications.location) {
              this.location = this.userMetadata.preferences.notifications.location; 
            }
            if (this.userMetadata.preferences.notifications.locationName) {
              this.locationName = this.userMetadata.preferences.notifications.locationName; 
            }
            if (this.userMetadata.preferences.notifications.distance) {
              this.distance = this.userMetadata.preferences.notifications.distance; 
            }
          }
        } catch (e) {
          console.error(e); 
        }

        mapboxgl.accessToken = API_KEY;
        if (!this.geocoder) {
          this.geocoder = new MapboxGeocoder({
              accessToken: mapboxgl.accessToken,
              types: 'place,postcode,locality,neighborhood,address'
          });

          this.geocoder.addTo('#geocoder');

          if (this.locationName) {
            this.geocoder.setInput(this.locationName); 
          }

          // Add geocoder result to container.
          this.geocoder.on('result', (e) => {
              this.location = e.result.center; 
              this.locationName = e.result.place_name; 
              this.errorMsg = ''; 
          });

          // Clear results container when search is cleared.
          this.geocoder.on('clear', () => {
              this.location = null; 
          });
        }
        this.pageLoaded = true; 
        console.log(this.pageLoaded); 
      } 
    },
    mounted() {
    },
    methods: { 
      async saveSettings() {
        let name = (this.user.attributes && this.user.attributes.preferred_username) ? this.user.attributes.preferred_username : this.user.attributes.name; 
        let email = this.user.attributes.email; 
        let username = this.user.username; 

        let body = {
          id: (this.userMetadata) ? this.userMetadata._id: null, 
          name: name, 
          email:email,
          username: username,
          preferences: {
            subscribe: this.newsletter, 
            notifications: {
              onPaddleAdd: this.paddleAddedNotify
            }
          }
        };

        if (this.paddleAddedNotify == 'withinDistance') {
          body.preferences.notifications.distance = this.distance; 
          if (!this.location) {
            this.errorMsg = 'Please enter a location'; 
            return; 
          }
          body.preferences.notifications.location = this.location; 
          body.preferences.notifications.locationName = this.locationName; 
        }

        body = JSON.stringify(body); 

        this.overlay = true; 
        await nextTick(); 
        try {
          let updateSettingsResponse = await NODE_API.put(`${constants.amazonGatewayUrl}/userMetadata/settings`,body, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
          this.userMetadata = updateSettingsResponse.data.ret.updatedMetadata; 
          if (this.userMetadata.preferences.notifications.onPaddleAdd != 'withinDistance') {
            this.distance = 100; 
            this.location = null; 
            this.geocoder.setInput(''); 
          }   
        } catch (e) {
          console.error(e); 
        }
        this.overlay = false; 
        if (this.isInDialog) {
          this.$emit('close'); 
        }
      },
    },
    computed: {
    
    },
    data: () => ({
      distances: [
        50, 100, 150, 200, 250, 300, 350, 400, 450, 500
      ],
      geocoder: null,
      location: null, 
      locationName: '',
      distance: 100, 
      userMetadata: null,
      user: null,
      minimap:null,
      newsletter: true,
      paddleAddedNotify: 'none',
      showErrorMessage: '',
      errorMsg: '',
      pageLoaded: false,
      overlay: false,
    }),
  }
</script>

<style lang="scss">
  .user-settings-overlay {
    .v-overlay__content {
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
    .v-overlay__scrim {
      opacity:0.5; 
    }
  } 
</style>

<style lang="scss" scoped>
  #minimap {
    width:250px; 
    height:300px; 
  }
  #geocoder {
    z-index: 1;
    margin-left: 10px;
    margin-top:-10px; 
  }
  .mapboxgl-ctrl-geocoder {
    min-width: 100%;
  }
  .save-user-settings-btn {
    margin-top:35px; 
  }
  .error-msg {
    min-height:0px!important;
    font-size:12px;
    line-height:15px;
    color: rgb(var(--v-theme-error)); 
  }
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .settings-location-hint {
    font-size: 12px;
    vertical-align: middle;
    padding-top: 29px;
    display: inline-block;
    padding-left: 20px;
    max-width:300px; 
  }
</style>
