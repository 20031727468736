import { createApp } from 'vue'
import App from './App.vue'
import RouteDrawTool from './pages/RouteDrawTool.vue'
import LandingPage from './pages/LandingPage.vue'
import MyPaddlesView from './pages/MyPaddlesView.vue'
import UserSettings from './pages/UserSettings.vue'
import Login from './pages/Login.vue'
import MainMap from './pages/MainMap.vue'
import IndividualView from './pages/IndividualView.vue'
import * as VueRouter from 'vue-router'
// import vuetify from './plugins/vuetify'
// import 'vuetify/dist/vuetify.min.css'
import awsconfig from './aws-exports';
import { Auth } from 'aws-amplify';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import './css/main.scss';

const paddleTrailsTheme = {
  dark: false,
  colors: {
    primary: '#084B83',
    secondary: '#FFFBFF',
    warning: '#df323b',
    accent: '#42BFDD',
    info: '#333333', 
    orangehighlight: '#FF4F00',
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'paddleTrailsTheme',
    themes: {
      paddleTrailsTheme,
    },
  },
})

let updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth, 
    "scope": [
      "aws.cognito.signin.user.admin",
      "email",
      "openid",
      "phone",
      "profile"
    ],
    "redirectSignIn": `${window.location.origin}/`,
    "redirectSignOut": `${window.location.origin}/`,
    "responseType": "code"
  }
}

Auth.configure(updatedAwsConfig);

const routes = [
  { path: '/about', component: LandingPage, name:'marketing' },
  { path: '/map', component: MainMap, name:'mainMap' }, 
  { path: '/map/:paddleName', component: MainMap, name: 'mainMapSingle'},
  { path: '/draw', component: RouteDrawTool, name:'routeDrawTool' },
  { path: '/edit/:username/:paddleName/:idPaddle', component: RouteDrawTool, name:'editPaddle'},
  { path: '/auth', component: Login, name:'loginView' },
  { path: '/myroutes', component: MyPaddlesView, name: 'myPaddlesView'},
  { path: '/user/settings', component: UserSettings, name: 'userSettings'},
  {path: "/:catchAll(.*)",redirect: '/map'}
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes, 
}); 

// Vue.use(VueRouter);
// new Vue({
//   vuetify,
//   router,
//   render: h => h(App)
// }).$mount('#app')

const app = createApp(App); 

app.config.ignoredElements = [/amplify-\w*/];
app.config.productionTip = false; 
app.use(vuetify); 
app.use(router); 
router.isReady().then(() => app.mount('#app')); 
