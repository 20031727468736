<template>
    <v-card class="main">
        <v-container fluid class="feature-image-section-container">
            <v-row>
                <v-col
                cols="12"
                md="12"
                class="feature-image-section"
                >
                   
                    <div class="feature-image-container">
                        <div class="feature-image"></div>
                        <v-btn
                            @click="$router.push('/map')"
                            color="orangehighlight"
                            >
                            Go To Map
                        </v-btn>
                        <div class="tagline-and-button-centered">
                            <div class="tagline text-h4 text-sm-h2">Explore on water</div>
                            <div class="tagline text-h4 text-sm-h2">Find your next fun day out</div>
                            <!-- <v-btn
                                color="orangehighlight"
                                >
                                Go To Map
                            </v-btn> -->
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid style="padding-top:120px;padding-bottom:150px;" class="bg-primary">
            <h2 class="featured-paddle-text">Find a route, float along.</h2> 
            <v-row class="featured-paddle-tiles">
            
                <v-col
                style="padding:20px;"
                v-for="paddle in featuredPaddles"
                cols="12"
                sm="6"
                md="4"
                >
                    <div class="tile"
                    @click="$router.push(`/map/${paddle.paddleNameSlug}`)">
                        <v-img  
                        class="tile-image"  
                        :height="200"
                        cover
                        :src="getPaddleImgUrl(paddle)">
                        </v-img>
                        <div class="tile-text">
                            <h3>{{ paddle.paddleName }}</h3>
                            <p>{{paddle.distance}} miles</p>
                            <p>{{getPaddleTypeNames(paddle.paddleType)}}</p>
                        </div>
                    </div>
                </v-col>
                <v-col
                class="d-none d-sm-flex d-md-none">
                    <div class="tile">
                        <v-img  
                        class="tile-image"  
                        :height="200"
                        cover
                        src="">
                        </v-img>
                    </div>
                </v-col>
                
            </v-row>
        </v-container>
       <!-- <v-container style="padding-top:20px;">  
            <v-row>
               <v-col
                cols="12"
                sm="6"
                >
                <p>
                    <h2>Discover</h2>
                    
                    <p>Search the map to find your next outing by kayak, canoe, paddle board or miscillaneous floating device.</p>
                    <p>Filter routes by location, length, paddle type and more!</p>
                </p>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <p>
                    <h2>Know before you go</h2>
                    <p>Don't get stuck in the log jam!</p>
                    <p>Use paddle info, description and comments to find the right route for you.</p>
                </p>
                </v-col> 
                <v-col
                cols="12"
                sm="12">
                <v-btn
                    class="btn-not-featured-image"
                    variant="outlined"
                    color="orangehighlight"
                    depressed
                >
                        Go To Map
                </v-btn>
                </v-col>
            </v-row>
        </v-container>-->
    
        <!--<v-card style="background-color:#ffffff; padding-bottom:50px;padding-top:30px;" flat >
            <v-row class="bg-image-row">     
            </v-row>
        </v-card>-->
        <v-container class="container-mid">  
            <h2 class="featured-paddle-text" style="color:black;">Share your trip.</h2>
            <v-row>
                
                <v-col
                cols="12"
                sm="6"
                >
                    <div class="draw-tool-image-bg"></div>
                
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
            
                    <div class="draw-tool-image-blurb"> 
          

                        <p>Draw your route and save it with the draw tool. Upload images, add a description.</p>
                        <p>Contribute it to the public map or keep it private.</p>
                    </div>
                    <v-btn
                        depressed
                        color="orangehighlight"
                        class="btn-not-featured-image"
                        @click="checkAuthenticateBeforeRouting"
                    >
                            Share it
                    </v-btn>
                </v-col>
                
            </v-row>
        </v-container>
        <v-container class="container-mid" style="display:none;">  
            <v-row>
                <v-col
                cols="12"
                sm="1" class="d-none d-sm-flex">
                </v-col>
                <v-col
                cols="12"
                sm="10">
                    <v-row> 
                        <v-col 
                        cols="12"
                        sm="4" style="padding:0px;">
                            <div class="phone-logo-icon-div"> 
                                <v-icon
                                    dark
                                    color="info"
                                    style="font-size:50px">
                                    mdi-android
                                </v-icon> 
                                <v-icon
                                    dark
                                    color="info"
                                    style="font-size:50px">
                                    mdi-apple
                                </v-icon>
                            </div>
                            <div class="phone-img-container">
                                <div class="phone-img">

                                </div>
                            </div>
                        </v-col>
                        <v-col 
                        cols="12"
                        sm="8">
                            <h2>Mobile App in Development Android and IOS</h2>
                            <p>Follow routes on your device.</p> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                        cols="12"
                        sm="6">
                            <v-text-field
                            v-model="signupEmail"
                            :rules="emailRules"
                            label="E-mail"
                            class="mb-2"
                            required
                            filled
                            color="accent"
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-text-field>
                        </v-col>
                        <v-col 
                        cols="12"
                        sm="6">
                            <v-btn
                            color="orangehighlight"
                            style="margin-top:1px;"
                            depressed
                            >
                            Email me when it's ready
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- <h2 style="margin-bottom:10px;">Mobile app</h2> -->
                   
                    <!-- <v-text-field
                        v-model="signupEmail"
                        :rules="emailRules"
                        label="E-mail"
                        class="mb-2"
                        required
                        filled
                        variant="outlined"
                        desity="compact"
                        hide-details
                    ></v-text-field>
                    <v-btn
                        color="orangehighlight"
                        variant="outlined"
                        depressed
                        class="btn-not-featured-image"
                        >
                        Email me when it's ready
                    </v-btn> -->
                </v-col>  
                <v-col
                cols="12"
                sm="1">
                </v-col>             
            </v-row>
        </v-container>
        <v-container fluid class="bottom-image-section-container">
            <div class="bottom-image"></div>


        </v-container>
       
        <v-footer style="background:transparent;">
            <v-row justify="center" no-gutters>
                <v-btn
                    v-for="link in links"
                    :key="link"
                    color="white"
                    variant="text"
                    class="mx-2"
                    rounded="xl"
                >
                    {{ link }}
                </v-btn>
                <v-col class="text-center mt-4" cols="12" style="color:white;margin-top:0px!important; ">
                    <strong>{{ new Date().getFullYear() }} — Paddle Routes</strong>
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
 
  </template>
<script>

import NavBar from '../components/NavBar.vue';
import NODE_API from '../utils/api';
import constants from '../utils/constants'; 
import { Auth } from 'aws-amplify';

export default {
    name: 'LandingPage',
    components: {
      NavBar
    },
    async beforeCreate() {
        let response = await NODE_API.get(`${constants.amazonGatewayUrl}/paddles/sample?num=3`); 
        this.featuredPaddles = response.data.ret; 
    },
    methods: { 
      getPaddleTypeNames(cPaddleType) {
        let namesArray = cPaddleType.map((c) => {
        return constants.cPaddleTypeNames[c]; 
        }); 
        return namesArray.join(', '); 
      },
      getPaddleImgUrl(paddle) {
        let featured = paddle.images.find((p) => p.order == 1); 
        return (featured) ? constants.imgBaseUrl + featured.url : ''; 
      },
      async checkAuthenticateBeforeRouting() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                this.$router.push('/draw');  
            }
        }  
        catch(err) {
            this.$router.push('/auth'); 
        }
      }
    },
    data: () => ({
        featuredPaddles: [], 
        signupEmail: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        links: [
            // 'About',
            // 'Social',
            // 'Contact Us',
        ]
    })
}
</script>

<style lang="scss" scoped>
.bottom-image-section-container {
    padding:0px; position:relative; 
    width:100%; 
    padding-bottom:35%; 
    opacity:0.7; 
    min-height:400px; 
}
.bottom-image {
    position:absolute; 
    left:0; right:0; top:0; bottom:0; 
    // background-image: url('../assets/landing_page_img/filler-image.jpg');  
    background-image: url('../assets/landing_page_img/david_canoe.jpeg'); 
    background-size: cover;
    background-position: center center; 
}
.featured-paddle-tiles {
    max-width: 1200px;
    margin:0 auto; 
}
.tile {
    cursor:pointer; 
    background-color:rgb(var(--v-theme-secondary)); 
    .tile-text {
        color:black; 
        min-height:120px; 
        padding-left:20px; 
        h3 {
            margin-bottom:10px; 
        }
    } 
}

h2 {
    font-weight:500; 
}
.btn-not-featured-image {
    // min-width: 50%;  
    margin-top: 25px; 
    display:block; 
}
.v-container {
    padding-left:40px; padding-right:40px;
}
.v-container.feature-image-section-container {
    padding:0px; 
}
.container-mid {
    padding-top:100px; 
    padding-bottom:150px; 
}
.tabs {
    width:auto; 
}
.feature-image-container {
    width:100%; 
    padding-bottom:50%; 
    position:relative;
    min-height:400px; 
}
.feature-image {
    position:absolute; 
    left:0; right:0; top:0; bottom:0; 
    background-image: url('../assets/landing_page_img/winter_group_paddle.jpeg');  //../assets/landing_page_img/david_canoe.jpeg
    background-size: cover;
}
.feature-image-section {
    padding-right:0px; padding-left:0px; padding-bottom:0px; padding-top:0px;
    padding-top:57px; 

    .tagline-and-button-centered {
        position:absolute; 
        z-index:10; 
        left: 50%;
        top: 50%;
        transform:translate(-50%,-50%); 
        width:100%; 
    }
    .tagline {
        color:#fffbff; 
        text-align: center;
        margin-bottom: 15px;
        display:none; 
    }
    .v-btn {
        width:40%; 
        font-weight:bold; 
        position: absolute;
        top: 15%;
        right: 10%;
    }
}
.featured-paddle-text {
    text-align:center; 
    color:#fffbff; 
    text-decoration-color: #fffbff;
    margin-bottom:50px; 
}
.tile {
    padding-top: 207px; 
    margin-top: 10px;
    position: relative;
    width: 100%;
    .tile-image {
        position: absolute;
        top: 0px;
        width:100%; 
    }
}


.bg-image-row {
    height:250px; 
    background-image: url('../assets/landing_page_img/winter_group_paddle.jpeg'); 
    background-size:cover;
    background-position:center; 
}
.draw-tool-image-bg {
    background-image: url('../assets/landing_page_img/draw_tool_2.png'); 
    background-size:cover;
    background-position:center; 
    padding-top: 50%;        
}
.draw-tool-image-blurb {
    p {
        margin-bottom:10px; 
    }
}
.phone-img-container {
    position:relative; 
    width:400px; 
    padding-bottom:40%; 
    .phone-img {
        position:absolute;
        left:0; top:0; bottom:0; right:0; 
        background-image: url('../assets/landing_page_img/phone_image.png'); 
        background-size:contain;
    }
}
.phone-logo-icon-div {
    display:flex; 
    justify-content:center; 
}

.v-footer {
    font-size:12px; 
    background: transparent;
    position: absolute;
    bottom: 0px;
}
a {
    color: rgb(var(--v-theme-orangehighlight)); 
}
</style>