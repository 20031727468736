<template>
  <v-list>
    <v-list-item
      v-for="(paddle, index) in paddles"
      :key="index"
    >
      
 
      <v-card flat>
        <v-img 
        v-if="paddle.images.length" 
        :width="300"
        cover
        :src="imageBaseUrl + paddle.images[0].url"
        ></v-img>
        {{ paddle.paddleName }}
      </v-card>
    </v-list-item>
  </v-list>
</template>

<script>

import constants from '../utils/constants'; 

export default {
  props: {
    paddles: Array,
  },
  mounted() {
      // console.log('paddles',this.paddles); 
  },
  methods: {
   
  },
  data() {
    return {
      imageBaseUrl: constants.imgBaseUrl
    }
  },
  computed: {
    
  },
}
</script>

<style lang="scss" scoped>
  

</style>
