<template>
  <v-card class="single-paddle-view" flat>
    <div class="top-btns" v-touch="{
      up: () => toggleSinglePaddleViewExpand(true),
      down: () => toggleSinglePaddleViewExpand(false)
    }">
      <v-btn class="back-to-route-btn" variant="flat" plain color="accent" @click="close()">
        <v-icon color="accent">mdi-arrow-left</v-icon>
        Back
      </v-btn>
      <v-icon class="view-expand-icon" @click="() => toggleSinglePaddleViewExpand(true)" v-if="isMobile && !isExpanded" color="accent" size="x-large">mdi-chevron-up</v-icon>
      <v-icon class="view-expand-icon" @click="() => toggleSinglePaddleViewExpand(false)" v-if="isMobile && isExpanded" color="accent" size="x-large">mdi-chevron-down</v-icon>
    </div>
    <v-carousel class="image-slides" v-if="paddle.images.length" 
      :show-arrows="paddle.images.length > 1"
      :hide-delimiters="paddle.images.length === 1">
        <v-carousel-item
          style="height:auto;"
          v-for="(image,index) in paddle.images"
          :src="getImageUrl(image)"
        ></v-carousel-item>
    </v-carousel>
    <v-list lines="one">
      <h1>{{ paddle.paddleName }}</h1>
      <div class="flex-container">
        <div class="flex-item-1"><span style="font-size:20px;">{{paddle.distance}} miles</span></div>
        <div class="flex-item-2" style="font-size:24px;"></div>
        <span class="flex-item-3">
          <v-btn 
            variant="flat" 
            text-color="accent" 
            density="comfortable"
            @click="goToDriveDirections"
          >
            <span class="drive-directions-link">Directions&nbsp;</span>
            <v-icon color="accent">mdi-open-in-new</v-icon>
          </v-btn>
        </span>
      </div>
      <p>{{ paddle.description }}</p>
      <div 
        v-if="paddle.isHazardous"
        class="warning-text-wrapper" 
        title="Conditions can be dangerous. Click for more details"
        @click="toggleWarningTextDetails(!showWarningTextDetails)">
        <v-icon color="warning">mdi-alert</v-icon>
        <span>Warning</span>
        <v-icon 
          v-if="!showWarningTextDetails"
          color="warning" 
          style="margin-left:-2px;margin-right:-2px;">
            mdi-chevron-down
        </v-icon>
        <v-icon 
        v-else
        color="warning" 
        style="margin-left:-2px;margin-right:-2px;">
          mdi-chevron-up
        </v-icon>
      </div>
      <div v-if="paddle.isHazardous && showWarningTextDetails" class="warning-text-details">
        {{ paddle.hazardDetails }}
      </div>
      <v-list-item
        v-if="paddle.paddleType.length">
        <v-list-item-title>
          <span>Paddle Type</span>
        </v-list-item-title>
        {{getPaddleTypeName()}}
      </v-list-item>
      <v-list-item>
        <v-list-item-title>
          <span class="flex-item-1">Launch Information</span> 
          <v-btn v-if="!zoomedToLaunch" class="boat-launch-zoom-wrapper" 
            variant="flat" 
            text-color="accent" 
            density="comfortable" 
            @click="zoomToLaunch">
            <span class="boat-launch-zoom-link">Zoom to launch</span>&nbsp;
            <v-icon color="accent" size="large">mdi-magnify-plus-cursor</v-icon>
          </v-btn>
          <v-btn v-if="zoomedToLaunch" class="boat-launch-zoom-wrapper" 
            variant="flat" 
            text-color="accent" 
            density="comfortable" 
            @click="zoomBackOut">
            <span class="boat-launch-zoom-link">Back</span>&nbsp;
            <v-icon color="accent" size="large">mdi-magnify-minus</v-icon>
          </v-btn>
        </v-list-item-title>
        {{getBoatLaunchTypeName()}}   
        <div v-if="paddle.launchDetails">{{ paddle.launchDetails }}</div>
      </v-list-item>
      <v-list-item
        v-if="paddle.outfitter">
    
        <v-list-item-title>
          <span>Outfitter</span>
        </v-list-item-title>
        <span v-if="!paddle.outfitterWebsite">{{paddle.outfitter}}</span>
        <a v-else :href="paddle.outfitterWebsite" target="_blank">{{ paddle.outfitter }}</a>
      </v-list-item>
    </v-list>
    <comments @commentAdded="$emit('commentAdded')" :paddleId="paddle._id"></comments>
  </v-card>
</template>



<script>
  import constants from '../utils/constants'; 
  import Comments from '../components/Comments.vue';

  export default {
    name: 'SinglePaddleView',
    emits: ['close','expand','shrink','centerOnRouteStart','zoomBackToRoute'], 
    components: {
      Comments
    },
    props: {
       paddle: Object,
       isMobile: Boolean,
    },
    mounted() {
    },
    methods: {
      goToDriveDirections() {
        window.open(this.driveDirectionsHref,'_blank');
      },
      getBoatLaunchTypeName() {
        return constants.cBoatLaunchTypeNames[this.paddle.launchType]; 
      },
      getPaddleTypeName() {
        let paddleTypeNameArray = this.paddle.paddleType.map((paddleTypeNumber) => constants.cPaddleTypeNames[paddleTypeNumber]); 
        let paddleTypeDisplay = paddleTypeNameArray.join(', '); 
        return paddleTypeDisplay; 
      },
      zoomBackOut() {
        this.zoomedToLaunch = false; 
        this.$emit('zoomBackToRoute'); 
      },
      zoomToLaunch() {
        this.zoomedToLaunch = true; 
        this.$emit('centerOnRouteStart'); 
      },
      close() {
        this.$emit('close'); 
      },
      getImageUrl(image) {
        return constants.imgBaseUrl + image.url; 
      },
      toggleWarningTextDetails(trueOrFalse) {
        if (trueOrFalse) {
          this.showWarningTextDetails = true; 
        } else {
          this.showWarningTextDetails = false; 
        }
      },
      toggleSinglePaddleViewExpand(trueOrFalse) {
        if (!this.isMobile ) return; 

        if (trueOrFalse) {
          this.$emit('expand'); 
          this.isExpanded = true; 
        } else {
          this.$emit('shrink'); 
          this.isExpanded = false;
        }
      }
    },
    computed: {
      driveDirectionsHref() {
        const startCoords = this.paddle.route.coordinates[0]; 
        return 'https://www.google.com/maps/dir/?api=1&destination=' + startCoords[1] + ',' + startCoords[0];
      },
    },
    data: () => ({
      isExpanded: false,
      zoomedToLaunch: false,
      showWarningTextDetails: false
    }),
  }
</script>

<style lang="scss" scoped>
  a {
    color: rgb(var(--v-theme-primary)); 
  }
  .warning-text-details {
    border: 2px dashed red;
    padding: 5px 7px;
    margin-bottom: 10px;
  }
  .warning-text-wrapper {
    padding-bottom:15px; 
    padding-top:5px; 
    cursor:pointer; 
    span {
      color: rgb(var(--v-theme-warning)); 
      font-size: 11px; 
      font-weight:bold; 
    }
  }
  .flex-item-1, .flex-item-3 {
    flex:1; 
  }
  .flex-item-1 + .v-btn {
    padding-inline:5px; 
  }
  .flex-item-3 {
    text-align:right; 
  }
  .v-list-item-title {
    display:flex; 
    justify-content:space-between; 
    span {
      opacity: var(--v-medium-emphasis-opacity);
      font-size: 0.875em;
    }
  }
  .boat-launch-zoom-wrapper {
    cursor:pointer; 
    opacity:1!important;
    margin-left: 20px;
    font-weight: bold;
    .boat-launch-zoom-link {
      opacity:1!important; 
      color: rgb(var(--v-theme-accent)); 
    }
  }
  .drive-directions-link {
    color: rgb(var(--v-theme-accent)); 
    display:inline-block; 
    font-size: 13px;
    font-weight: bold;
  }
  .v-list {
    padding:10px; 
    h1 {
      display:inline-block;
      font-weight:400; 
    }
    .flex-container {
      display:flex; 
      align-items:center; 
      padding-top:10px; 
      & + p {
        padding-bottom:10px; 
        padding-top:10px; 
      }
    }
  }
  .v-list-item {
    padding-inline:0px!important;
    font-size: 1em;
    opacity: 1;
    span {
      opacity: var(--v-medium-emphasis-opacity);
      font-size: 0.875em;
    }
    & + .v-list-item {
      padding-top:10px; 
    }
  }
  .view-expand-icon {
    font-size: 50px;
    position: absolute;
    left: 50%;
    margin-left: -25px;
  }
  .image-slides {
    padding:10px; 
  }
  .back-to-route-btn {
    padding: 0px 16px 0px 12px; 
  }
  .top-btns {
    display:flex; 
    justify-content:space-between; 
    padding:10px 10px 0px; 
    align-items:center;
  }
  .v-btn.bg-accent {
    color:white!important; 
    i.v-icon {
      color:white!important; 
    }
  }
</style>
