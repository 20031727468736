<template>
    <v-dialog max-width="500" :modelValue="show" @update:model-value="close">
        <v-card
        >
            <div v-if="checkmarkAnimation && callbackCalled" :class="{'circle-loader':true,'load-complete':this.callbackFinished}">
                <div v-if="showCheckmark" class="checkmark draw"></div>
            </div>
            <span v-html="message" style="padding:10px;"></span>
            <v-card-actions v-if="showButtons">
                <v-btn
                variant="flat"   
                @click="close"  
                > 
                    {{(cancelBtnText) ? cancelBtnText : 'Close'}}
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                :color="(confirmBtnColor) ? confirmBtnColor : 'accent'"
                variant="flat"   
                @click="goAheadAndSave"
                > 
                    {{(confirmBtnText) ? confirmBtnText : 'Confirm'}}
                </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
<script>

import { nextTick } from 'vue'

export default {
    props: {
        message: {
            type: String,
            default: ''
        },
        errorMsg: {
            type: String,
            default: ''
        },
        cancelBtnText: {
            type: String, 
            default: 'Cancel'
        },
        confirmBtnText: {
            type: String, 
            default: 'Confirm'
        },
        confirmBtnColor: {
            type: String, 
            default: 'accent'
        },
        callback: {
            type: Function, 
            default: () => {}
        }, 
        show: {
            type: Boolean, 
            default: false
        }, 
        checkmarkAnimation: {
            type: Boolean, 
            default: false
        }, 
        stayUp: {
            type: Boolean, 
            default: false
        }, 
        showButtons : {
            type: Boolean, 
            default: true
        }
    },
    data: () => ({
        callbackCalled: false, 
        callbackFinished: false, 
        showCheckmark: false, 
    }),
    emits: ['close', 'checkmarkAnimationStarted', 'checkmarkAnimationFinished'],
    methods: {
        async goAheadAndSave() { 
            try {
                if (this.checkmarkAnimation) {
                    this.callbackCalled = true; 
                    this.$emit('checkmarkAnimationStarted', this.props); 
                }
                
                await this.callback(); 

                if (this.checkmarkAnimation) {
                    this.callbackFinished = true; 
                    this.showCheckmark = true; 
                    this.$emit('checkmarkAnimationFinished', this.props); 
                }
            } catch(e) {
                this.$emit('close',this.errorMsg); 
            }

            await nextTick(); 
            if (!this.stayUp) {
                if (this.checkmarkAnimation) {
                    setTimeout(() => {
                        this.$emit('close'); 
                    }, 1000); 
                } else {
                    this.$emit('close'); 
                }
            }
        },
        close(trueOrFalse) {
            if (trueOrFalse) {
                if (this.checkmarkAnimation) {
                    this.callbackCalled = false; 
                    this.callbackFinished = false; 
                    this.showCheckmark = false; 
                }
                this.$emit('close'); 
            }
        }
    }
}
</script>

<style lang="scss" scoped>
 .v-btn.bg-accent {
    color:white!important; 
    i.v-icon {
      color:white!important; 
    }
  }

$brand-success: rgb(var(--v-theme-orangehighlight)); 
$loader-size: 4em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;

.circle-loader {
  margin: 20px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
</style>
  