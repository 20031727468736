<template>
  <spinner-overlay :model-value="overlay" :persistent="true"></spinner-overlay>
  <confirm-dialog 
    v-bind="confirmationDialog"
    @close="confirmationDialog.show = false">
  </confirm-dialog>
  <v-list ref="commentList" class="comment-list">
    <div class="comment-list-header">
      <v-icon
        color="primary"
        icon="mdi-message-text"
      ></v-icon><span class="ml-1">Comments</span>
    </div>
    <div class="text-area-container" v-if="user">
      <v-textarea
        v-model="commentToAdd"
        density="compact"
        auto-grow
        outlined
        rows="1"
        class="comment-textarea"
        label="Add comment"
        hide-details></v-textarea>
        <v-btn :density="'compact'" :variant="'flat'" class="save-comment-link" @click="saveComment">Save</v-btn>
    </div>
    <div v-else class="must-login">
      <a href="/auth">Login</a> to comment
    </div>
    <template v-for="(thread,threadIndex) in groupedByThread">
      <div class="thread-container">
        <!-- <hr v-if="threadIndex == 0" class="comment-line" style="margin-left:8px;"> -->
        <template v-for="(comment, index) in thread">
          <v-list-item :class="getCommentClasses(comment)">
            <!-- <hr v-if="comment.level >= 2" class="comment-line"/> -->
            <div v-if="comment.deleted" style="background-color:gray; opacity: 0.7; text-decoration:line-through;">Comment deleted</div>
            <template v-else>
              <v-list-item-subtitle><span style="opacity:0.7">{{comment.user}} • {{ new Date(comment.dttimestamp) }}</span></v-list-item-subtitle>
              <v-list-item-title>
                <span v-if="!isEditing[comment._id]">{{ comment.text }}</span>
                <span class="'crud-btn-wrapper'" v-if="!isEditing[comment._id]">
                  <v-btn 
                    :variant="'flat'" 
                    :density="'compact'" 
                    class="crud-link" 
                    v-if="userCanEdit(comment.user)"
                    @click="(evt) => showEditTextArea(comment)">Edit</v-btn>&nbsp;&nbsp; 
                  <v-btn 
                    :variant="'flat'" 
                    :density="'compact'" 
                    class="crud-link" 
                    v-if="userCanDelete(comment.user)"
                    @click="(evt) => showConfirmationPrompt(comment)">Delete</v-btn>
                </span>
                <v-textarea  
                  v-if="isEditing[comment._id]"
                  v-model="commentToEdit"
                  density="compact"
                  auto-grow
                  outlined
                  autofocus 
                  rows="1"
                  class="comment-textarea pl-0"
                  hide-details></v-textarea>
                <span class="crud-btn-wrapper is-editing" v-if="isEditing[comment._id]">
                  <v-btn 
                    :variant="'flat'" 
                    :density="'compact'" 
                    class="crud-link" 
                    @click="(evt) => hideEditTextArea(comment)">Cancel</v-btn>&nbsp;&nbsp; 
                  <v-btn 
                    :density="'compact'" 
                    :variant="'flat'" 
                    class="save-comment-link" 
                    @click="(evt) => editComment(evt,comment)">Save</v-btn>
                </span>
              </v-list-item-title>
              <v-btn 
                v-if="!isEditing[comment._id] && user" 
                :variant="'flat'" 
                :density="'compact'" 
                @click="toggleReplyTextArea(threadIndex + '_' + index)" 
                class="reply-link">Reply</v-btn>
              <div class="text-area-container mb-0 mt-1" v-if="replyToggle[threadIndex + '_' + index]">
                <v-textarea   
                  v-model="replyToAdd"
                  density="compact"
                  autofocus
                  outlined
                  rows="1"
                  auto-grow
                  class="comment-textarea pl-0"
                  label="Add reply"
                  hide-details></v-textarea>
                <v-btn :density="'compact'" :variant="'flat'" class="save-comment-link" @click="(e) => saveComment(e, comment)">Save</v-btn>
              </div>
            </template>
          </v-list-item>
        </template>
      </div>
    </template>
  </v-list>
</template>

<script>
  import NODE_API from '../utils/api';
  import constants from '../utils/constants'; 
  import { Auth } from 'aws-amplify';
  import ConfirmDialog from './ConfirmDialog.vue';
  import Login from '../pages/Login.vue';
  import { nextTick } from 'vue';
  import SpinnerOverlay from '../components/SpinnerOverlay.vue';

export default {
  components: {
      ConfirmDialog,
      Login,
      SpinnerOverlay
  },
  props: {
    paddleId: String
  },
  data() {
    return {
      overlay: false,
      isEditing: {}, 
      commentToAdd: '',
      commentToEdit: '',
      user: false, 
      replyToggle: {},
      comments: [], 
      confirmationDialog: {
        show: false, 
        message: '', 
        callback: null,
        confirmBtnText: '',
        cancelBtnText: ''
      }, 
    }
  },
  destroyed() {

  },
  async mounted() {
    try {
      this.user = await Auth.currentAuthenticatedUser(); 
    } catch(e) {
      this.user = false; 
    }
  
    let response = await NODE_API.get(`${constants.amazonGatewayUrl}/comment?paddleId=${this.paddleId}`);
    this.comments = response.data.ret; 
  },
  computed: {
    groupedByThread() {
      let topLevelComments = this.comments.filter((comment) => {
        return comment.level == 1; 
      }); 

      const keyByIdAndOrder = (array, comment) => {
        let threadArray = [comment]; 
        this.findAndPushNextComment(comment._id,threadArray); 
        // console.log(threadArray); 
        array.push(JSON.parse(JSON.stringify(threadArray)));
        return array;   
      }
      //comments grouped by thread
      let commentArray = topLevelComments.reduce(
        keyByIdAndOrder, 
        []
      );
      return commentArray; 
    }
  },
  methods: {
    getCommentClasses(comment) {
      let classString = 'ml-' + (comment.level - 1) * 6 
      classString += (comment.level > 2) ? ' n-level-more-than-two': ''; 
      classString += (comment.level == 2) ? ' n-level-two': ''; 
      return classString; 
    },
    hideEditTextArea(comment) {
      this.commentToEdit = ''; 
      this.isEditing[comment._id] = false; 
    },
    showEditTextArea(comment) {
      this.commentToEdit = comment.text; 
      this.isEditing = {}; 
      this.isEditing[comment._id] = true; 
      this.replyToggle = {}; 
      this.replyToAdd = ''; 
    }, 
    async showConfirmationPrompt(comment) {
      let descendants = this.comments.filter((c) => c.ancestors.includes(comment._id)); 
      let hasDescendants = descendants.length ? true : false;
      const body = {
        commentId: comment._id, 
        user: this.user.username, 
        paddleId: comment.paddleId,
        hasDescendants: hasDescendants,
        parent: comment.parent
      }
      const deleteComment = async () => {
        this.overlay = true; 
        const response = await NODE_API.delete(`${constants.amazonGatewayUrl}/comment`, {
          data: body
        });

        if (response.data.success) {
          this.comments = response.data.ret; 
        } else {
          console.error('something went wrong with comment delete'); 
        }
        this.overlay = false; 
      }; 

      this.confirmationDialog.callback = deleteComment
      this.confirmationDialog.show = true; 
      this.confirmationDialog.message = `Are you sure you want to delete this comment?`;
      this.confirmationDialog.confirmBtnText = 'Delete'; 
      this.confirmationDialog.cancelBtnText = 'Cancel'; 
    },
    userCanDelete(username) {
      if (!this.user) {
        return; 
      }
      if (username === this.user.username || this.user.attributes['custom:isAdmin'] == 1) {
        return true; 
      } else {
        return false; 
      }
    },
    userCanEdit(username) {
      if (!this.user) {
        return; 
      }
      if (username === this.user.username) {
        return true; 
      } else {
        return false; 
      }
    }, 
    async editComment(evt,comment) {
      this.overlay = true; 
      let body = {
        commentId: comment._id, 
        paddleId: comment.paddleId, 
        text: this.commentToEdit
      }

      try { 
        let res = await NODE_API.put(`${constants.amazonGatewayUrl}/comment`, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        }); 
        this.comments = res.data.ret; 
      } catch (e) {
        console.error('Unable to edit comment',e); 
      }

      this.commentToEdit = ''; 
      this.isEditing = {}; 
      this.overlay = false; 
    },
    async saveComment(e, parentComment = null) {
      
      if (!this.user) {
        this.showLoginDialog = true; 
        return; 
      }
      this.overlay = true; 
      let body = {}; 
      if (parentComment) {
        body = {
          paddleId: this.paddleId, 
          parent: parentComment._id, 
          level: parentComment.level + 1, 
          ancestors: [...parentComment.ancestors, parentComment._id], 
          text: this.replyToAdd, 
          user: this.user.username
        }; 
      } else {
        body = {
          paddleId: this.paddleId,
          parent: null,
          level: 1, 
          ancestors: [], 
          text: this.commentToAdd, 
          user: this.user.username
        }; 
      }
  
      try { 
        let res = await NODE_API.post(`${constants.amazonGatewayUrl}/comment`, body, {
          headers: {
            'Content-Type': 'application/json'
          }
        }); 

        this.comments = res.data.ret; 
      } catch (e) {
        console.error('Unable to add comment',e); 
      }

      this.replyToAdd = ''; 
      this.commentToAdd = ''; 
      this.replyToggle = {}; 
      this.overlay = false; 

      this.$emit('commentAdded'); 
    }, 
    toggleReplyTextArea(textAreaKey) {
      if(this.replyToggle[textAreaKey]) {
        this.replyToggle[textAreaKey] = false; 
      } else {
        this.replyToggle[textAreaKey] = true; 
      }
    },
    findAndPushNextComment(currentCommentId, threadArray) {
      let childComments = this.comments.filter((comment) => comment.parent == currentCommentId);
      if (childComments.length) {
        childComments.forEach((child) => {
          threadArray.push(child); 
          this.findAndPushNextComment(child._id, threadArray); 
        }); 
      } 
    }, 
  }
}
</script>

<style lang="scss" scoped>
  .crud-btn-wrapper.is-editing {
    display:flex; 
    align-items:center; 
    .save-comment-link {
      color: rgb(var(--v-theme-accent)); 
    }
  }
  .text-area-container {
    position:relative; 
    margin-bottom:20px; 
    .save-comment-link {
      position: absolute;
      right: 26px;
      bottom: 5px;
      cursor: pointer;
      color: rgb(var(--v-theme-accent)); 
    }  
  }
  .comment-list {
    margin-top:10px; 
    padding-left:0px; 
  }
  .comment-textarea {
    padding-inline:10px; 
  }
  .comment-list-header {
    padding-inline:10px; 
    padding-bottom:8px; 
    font-size: 0.875em;
    span {
      opacity: var(--v-medium-emphasis-opacity);
    }
  }
  .reply-link {
    cursor:pointer; 
    font-size: 11px; 
    color: rgb(var(--v-theme-primary)); 
    padding-inline:0px; 
    min-width:0px; 
  }
  .crud-link {
    @extend .reply-link;  
    color: rgb(var(--v-theme-accent)); 
  }
  .v-list-item {
    padding-bottom:5px!important; 
    margin-bottom:10px; 
    border-left:1px dashed lightgray; 
  }
  .v-list-item-subtitle {
    font-size:12px; 
    font-weight:bold; 
  }
  .v-list-item-title { 
    padding-top: 5px;
    padding-bottom: 0px;
    text-wrap:wrap; 
    display: flex;
    justify-content: space-between;
  }
  .must-login {
    padding: 16px;
    padding-top: 10px;
    font-size: 13px;
    a {
      color: rgb(var(--v-theme-accent)); 
      font-weight:bold; 
    }
  }
  // .comment-line {
  //   transform:rotate(90deg); 
  //   transform-origin: bottom left; 
  //   border: 1px solid grey;
  //   width: 100%;
  //   position:absolute; 
  //   opacity:0.2; 
  // }
  // .thread-container {
  //   overflow:hidden; 
  // }

  // .n-level-two {
  //   :deep(.v-list-item__content) {
  //     margin-left: 3px; 
  //     .comment-line {
  //       margin-left: -10px;  
  //     }
  //   }
  // }
  // .n-level-more-than-two {
  //   @extend .n-level-two; 
  //   overflow:hidden; 
  // }


</style>
