export default {
    amazonGatewayUrl: 'https://avel9ze9fd.execute-api.us-east-2.amazonaws.com/default', 
    imgBaseUrl: 'https://paddletrailimg.s3.us-east-2.amazonaws.com/',
    imgResizedBaseUrl: 'https://paddletrailimg-resized.s3.us-east-2.amazonaws.com/',
    cBoatLaunchTypes: {
        CARPARK:1, 
        BOATLAUNCH:2, 
        BEACH:3, 
        ROADSHOULDER:4
    },
    cPaddleTypes: {
        BAYOU:1, 
        RIVER:2, 
        OCEAN:3,
        LAKE:4,
        FLATWATER:5,
        WHITEWATER:6
    },
    cPaddleTypeNames: {
        1: 'Bayou', 
        2: 'River',
        3: 'Ocean', 
        4: 'Lake',
        5: 'Flatwater',
        6: 'Whitewater'
    },
    cBoatLaunchTypeNames: {
        1: 'Car Park',
        2: 'Boat Launch',
        3: 'Beach',
        4: 'Road Shoulder'
    }
}