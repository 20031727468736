<template>
  <v-overlay class="main-map-overlay" :model-value="overlay" :persistent="true">
    <v-progress-circular
          indeterminate
          color="accent"
          :size="60"
        ></v-progress-circular> 
  </v-overlay>
  <div class="paddles-loading-overlay" v-show="!paddlesAreLoaded && !promptForLocation && !singlePaddleLoaded">
    <v-progress-circular
      class="paddles-loading-spinner"
      indeterminate
      color="accent"
      :size="60"
    ></v-progress-circular> 
  </div>
  <div v-if="showInfoPopup" class="info-popup">No routes?<br><a @click="goToSignUpPage()">Sign up</a> to be notified as new routes are added near you.   <v-icon color="primary" @click="showInfoPopup = false">mdi-close</v-icon></div>
  <v-main :class="{'is-not-mobile':$vuetify.display.smAndUp, 'is-mobile':!$vuetify.display.smAndUp}" style="--mobile-single-paddle-height:40vh;--not-mobile-single-paddle-width:50%">
      <!-- <aside :class="{'paddle-list': true, 'show': paddlesAreLoaded}" >
      <template v-if="paddlesAreLoaded">
        <main-map-paddle-list
          :paddles=paddles
        ></main-map-paddle-list>
      </template>
      </aside> -->
      <div id="map" :class="{'map--prompt-location':promptForLocation, 'shrink-map-for-single-paddle-view':showHideSinglePaddleView, 'markers-not-ready':!paddlesAreLoaded}"></div>  
      <v-select
        class="main-map-filter filter-paddle-type"
        label="Select"
        v-model="paddleType"
        :items="paddleTypes"
        density="compact"
        variant="solo"
        hide-details
        clearable
        multiple
        persistent-clear
        :color="'primary'"
      >
        <template 
          v-slot:label>
          <span v-if="paddleType.length == 0">Paddle Type</span>
        </template>
        
      </v-select>
      <v-select
        class="main-map-filter filter-distance"
        label="Select"
        v-model="distance"
        :items="distanceRanges"
        density="compact"
        variant="solo"
        hide-details
        clearable
        persistent-clear
        :color="'primary'"
      >
        <template 
          v-slot:label>
          <span v-if="!distance">Distance</span>
        </template>
        
      </v-select> 
      <v-btn
          v-if="!promptForLocation"
          class="regular-search-get-current-location-btn my-ctrl"
          depressed
          density="compact"
          @click="useCurrentLocation(true)"
          title="Get your location to use in the search bar"
        >
                 
          <v-icon
            dark
            v-show="!isGettingLocation"
            >
            mdi-crosshairs-gps
          </v-icon>
          <v-progress-circular
            v-show="isGettingLocation"
            indeterminate
            size="18"
            width="2"
          ></v-progress-circular>
       </v-btn>
      <v-btn 
        v-if="showHideSinglePaddleView"
        small depressed 
        density="comfortable"
        class="gen-pdf my-ctrl" 
        @click="generatePdf()"
        title="Download PDF">
        <v-icon
          dark
          color="info">
          mdi-download
        </v-icon>
      </v-btn> 
      <div class="zoom-btns">
        <v-btn 
          small depressed 
          density="comfortable"
          class="zoom-in my-ctrl" 
          @click="mainMap.zoomIn()"
          title="Zoom in">
          <v-icon
            dark
            color="info">
            mdi-plus-thick
          </v-icon>
        </v-btn>
        <v-btn 
          small depressed 
          density="comfortable"
          class="zoom-out my-ctrl" 
          @click="mainMap.zoomOut()"
          title="Zoom out">
          <v-icon
            dark
            color="info">
            mdi-minus-thick
          </v-icon>
        </v-btn>  
      </div>
      <aside :class="{'paddle-details':true, 'show':showHideSinglePaddleView, 'expanded':(!$vuetify.display.smAndUp && mobileExpanded)}">
        <single-paddle-view
          @close="closeSinglePaddleView()"
          @expand="mobileExpanded = true"
          @shrink="mobileExpanded = false"
          @centerOnRouteStart="centerOnRouteStart()"
          @zoomBackToRoute="zoomBackToRoute()"
          @commentAdded="nudgeScroll"
          v-if="thePaddleToShow"
          :paddle="thePaddleToShow"
          :isMobile="!$vuetify.display.smAndUp"> 
        </single-paddle-view>
      </aside>
      <div v-if="promptForLocation" class="prompt-for-location-container">
        <mapbox-search-box
          class="prompt-location-search-box"
          :access-token="mapboxApiKey"
          proximity="0,0"
        >
        </mapbox-search-box>
        or 
        <v-btn
          class="icon-btn-locate"
          depressed
          color="accent"
          @click="useCurrentLocation()"
          title="Get your location to use in the search bar"
        >
                 
          <v-icon
            dark
            color="white"
            v-show="!isGettingLocation"
            style="padding-right:10px;"
            >
            mdi-crosshairs-gps
          </v-icon>
          <v-progress-circular
            v-show="isGettingLocation"
            indeterminate
            size="18"
            width="2"
            color="white"
            style="margin-right:10px;"
          ></v-progress-circular>
        Use my location 
       </v-btn>
       <div v-if="showLocationDeniedTooltip" class="location-denied-hint">
          Location needs to be allowed for this site in browser settings for this to work
       </div>
      </div>

  </v-main>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import MainMapPaddleList from '../components/MainMapPaddleList.vue';
import SinglePaddleView from './SinglePaddleView.vue';
import { Auth } from 'aws-amplify';
import {MainMap} from '../utils/mainMap';
import NODE_API from '../utils/api';
import constants from '../utils/constants'; 
import {nextTick} from 'vue';
import debounce from 'debounce';

export default {
  name: 'MainMap',
  components: {
    NavBar, MainMapPaddleList, SinglePaddleView
  },
  async mounted() {
    const {paddleName} = this.$route.params; 
    if (paddleName) {
      //try to fetch the paddle and display it. 
      const singlePaddle = await this.fetchSinglePaddle(paddleName); 
      if (singlePaddle) {
        this.initMapForSinglePaddleView(singlePaddle); 
      } else {
        await this.initMap(); 
      }
    } else {
      await this.initMap(); 
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
          
      }
    }  
    catch(err) {
        this.showInfoPopup = true; 
    }
  },
  beforeUnmount() {
    this.mainMap.removeMapDragEndListeners(this.debouncedFetchPaddlesInBounds()); 
    document.querySelector('html').classList.remove("is-showing-single-paddle-view"); 
    if (this.searchBoxRef) {
      this.searchBoxRef.removeEventListener('retrieve',this.flagSearchSelected);
    }
  },
  watch: {
    showHideSinglePaddleView(newValue) {
      if (newValue) {
        document.querySelector('html').classList.add("is-showing-single-paddle-view");
      } else {
        document.querySelector('html').classList.remove("is-showing-single-paddle-view"); 
      }
    }
  },
  methods: {
    debouncedFetchPaddlesInBounds() {
      return debounce(this.fetchPaddlesInBounds,500); 
    },
    goToSignUpPage() {
      this.$router.push({name:'loginView'}); 
    },
    async initMapForSinglePaddleView(singlePaddle) {
      this.thePaddleToShow = singlePaddle; 
      this.showHideSinglePaddleView = true;  
      
      nextTick(async () => {
        this.mainMap = new MainMap();
        await this.mainMap.initMap(); 
        this.mainMap.map.on('load', () => {
          this.mainMap.drawPaddleRoutes([singlePaddle._id],[singlePaddle]);
          this.mainMap.toggleLineArrowLayer(true);        
          nextTick(() => {  
            let routeCoords = singlePaddle.route.coordinates;   
            this.mainMap.fitBoundsToRoute(routeCoords);   
            this.singlePaddleLoaded = true;       
          }); 
        });  
      });
    },
    async initMap(centerCoordinates = null) {

      if (!centerCoordinates) {
        //get center coordinates based on last searched location. 
        let strLastSearchedLocation = localStorage.getItem('lastSearchedLocation'); 
        if (strLastSearchedLocation) {
          let lastSearchedLocation = JSON.parse(strLastSearchedLocation); 
          centerCoordinates = [ lastSearchedLocation.coordinates.lng, lastSearchedLocation.coordinates.lat ]; 
        } else {
          //prompt for search
          this.promptForLocation = true; 
        }
      }

      this.mainMap = new MainMap();
      await Promise.all([
        this.mainMap.initMap(centerCoordinates), 
      ]); 

      if (this.promptForLocation) {
      //prompt user to enter search location 
        let searchBoxComponent = document.querySelector(".prompt-location-search-box"); 
        searchBoxComponent.focus(); 
        searchBoxComponent.bindMap(this.mainMap.map); 
        searchBoxComponent.addEventListener('retrieve',(event) => {
          this.promptForLocation = false; 
          this.saveMostRecentMapboxSearch(event);
          //hide big search box, show little one  
          this.initializeDefaultSearchBox(); 
          this.fetchPaddlesInBounds(); 
          this.mainMap.addMapDragEndListener(this.debouncedFetchPaddlesInBounds()); 
        }); 
        const searchBoxInput = document.querySelector('.prompt-location-search-box input'); 
        this.addSearchBoxPlaceholder(searchBoxInput); 
      } else {
        //have zoomed in on location 
        this.initializeDefaultSearchBox(); 
        this.fetchPaddlesInBounds(); 
        this.mainMap.addMapDragEndListener(this.debouncedFetchPaddlesInBounds()); 
      }
    },
    nudgeScroll() {
      //scroll down so user can see comment has been added
      nextTick(() => {
        const paddleDetailEl = document.querySelector('.paddle-details');   
        paddleDetailEl.scrollTop = paddleDetailEl.scrollTop + 200; 
      }); 
    },
    generatePdf() {
      this.overlay = true; 
   
      nextTick(() => {
        const mapRef = document.querySelector('#map'); 
        mapRef.classList.add("a4-size"); 
        
        this.mainMap.map.once('idle',() => {
          this.mainMap.generatePdf(this.thePaddleToShow); 
          mapRef.classList.remove("a4-size"); 
          this.mainMap.resize(); 
          nextTick(() => {
            this.zoomBackToRoute(); 
            nextTick(() => {
              this.overlay = false; 
            }); 
          }); 
        });

        nextTick(() => {
          this.mainMap.resize(); 
          nextTick(() => {
            this.zoomBackToRoute(); 
          }); 
        });     
      });   
    },
    zoomBackToRoute() {
      let routeCoords = this.thePaddleToShow.route.coordinates;   
      this.mainMap.fitBoundsToRoute(routeCoords);    
    },
    centerOnRouteStart() {
      let routeStart = this.thePaddleToShow.route.coordinates[0].reduce((prev,curr,index) => {
        if (index == 0) {
          prev.lng = curr; 
        } 
        if (index == 1) {
          prev.lat = curr; 
        }
        return prev; 
      },{}); 
      this.mainMap.centerOnLocation(routeStart, 14);
    }, 
    closeSinglePaddleView() {

      let singlePaddleLat 
      let singlePaddleLng; 
      if (this.singlePaddleLoaded) {
        //haven't initialize map yet. 
        //initialize it with center coordinates on this paddle 
        [singlePaddleLng,singlePaddleLat] = this.thePaddleToShow.location; 
      }

      this.showHideSinglePaddleView = false; 
      this.thePaddleToShow = null; 
      //undraw the routes
      this.mainMap.toggleLineArrowLayer(false); 
      this.idPaddlesToKeepDrawnOnMap = [];
      this.mainMap.removeAllRoutes([]); 

      if (this.singlePaddleLoaded) {
        //still haven't initialized map. 
        nextTick(() => {
          this.initMap([singlePaddleLng, singlePaddleLat]); 
          this.singlePaddleLoaded = false;
          this.$router.push('/map'); 
        }); 
      } else {
        nextTick(() => {
          this.returningOutOfSinglePaddleView = true; 
          //moveend handler will fire when map resized 
          this.mainMap.resize(); 
          let {center, zoom} = this.prevMapPosition; 
          //moveend handler will fire when map flown to 
          this.mainMap.map.flyTo({center, zoom, animate:false}); 
          this.returningOutOfSinglePaddleView = true; 
        }); 
      }
    },
    removeMoreInfoClickHandler(paddleId, popup) {
      if (this.moreInfoLink[paddleId]) {
        this.moreInfoLink[paddleId].removeEventListener('click',this.moreInfoClickHandler); 
        this.infoHandlerAddedForPaddle = this.infoHandlerAddedForPaddle.filter((id) => id != paddleId); 
      }
    },
    attachMoreInfoClickHandler(paddleId, popup) {
      this.moreInfoClickHandler = () => {
        this.prevMapPosition = {
          center: this.mainMap.map.getCenter(),
          zoom: this.mainMap.map.getZoom() 
        }; 
        this.showHideSinglePaddleView = !this.showHideSinglePaddleView;  
        this.thePaddleToShow = this.paddles.find((paddle) => paddle._id === paddleId); 
        nextTick(() => {
          this.mainMap.resize(); 
          if (this.showHideSinglePaddleView) {
            let routeCoords = this.thePaddleToShow.route.coordinates; 
            this.mainMap.toggleLineArrowLayer(true);        
            nextTick(() => {    
              this.mainMap.fitBoundsToRoute(routeCoords);         
              popup.remove();
            }); 
          }
        });  
      }; 
      this.moreInfoLink[paddleId] = document.querySelector('.more-info-text--' + paddleId); 

      if (!this.infoHandlerAddedForPaddle.includes(paddleId)) {
        this.moreInfoLink[paddleId].addEventListener('click',this.moreInfoClickHandler);
        this.infoHandlerAddedForPaddle.push(paddleId); 
      }
    }, 
    formatData(paddles) {
      let formatted = paddles.map((p) => {
        p.distance = parseFloat(p.distance); 
        return p; 
      });  
      return formatted; 
    }, 
    async fetchSinglePaddle(paddleSlug) {
      try {
        let response = await NODE_API.get(`${constants.amazonGatewayUrl}/paddle?paddleNameSlug=${paddleSlug}`);
        return response.data.ret; 
      } catch (e) {
        console.error(e); 
      }
    }, 
    async fetchPaddlesInBounds(evt) {
      if (evt && evt.type == 'zoomend') {
        //only fetch if bigger bounding box than currently fetched for. 
        let newZoomLevel = this.mainMap.map.getZoom(); 
        if (newZoomLevel < this.paddlesFetchedForZoomLevel || this.searchBoxSearchInProgress) {
          this.searchBoxSearchInProgress = false; 
          //go ahead
        } else {
          //zoomed in. there wont be any new paddles to fetch 
          return; 
        }
      }

      if (this.showHideSinglePaddleView || this.returningOutOfSinglePaddleView) { 
        this.returningOutOfSinglePaddleView = false; 
        //don't refetch the paddles. just zoom in and zoom back. 
        return; 
      }

      const popupShowing = document.querySelector('.mapboxgl-popup'); 

      if (popupShowing) {
        return; 
      }

      this.paddlesAreLoaded = false; 
      let mapBounds = this.mainMap.map.getBounds().toArray(); 
      let bottomLeft = mapBounds[0]; 
      let topRight = mapBounds[1]; 
      let body = JSON.stringify({ bottomLeft, topRight });

      if (this.controller) {
        this.controller.abort(); 
      }

      this.controller = new AbortController();
  
      try {
        let response = await NODE_API.post(`${constants.amazonGatewayUrl}/paddles`,body, {
          headers: {
            'Content-Type': 'application/json'
          },
          signal: this.controller.signal
        }); 
      
        this.controller = null; 

        this.paddles = this.formatData(response.data.ret); 

        this.addMapMarkers(); 
        this.paddlesAreLoaded = true; 
        this.paddlesFetchedForZoomLevel = this.mainMap.map.getZoom(); 
      } catch (e) {
        console.error('error fetching paddles',e); 
      }
    },
    addMapMarkers() {
      const mouseEnterCallback = (paddleId) => {
          if (!this.paddlesAreLoaded) {
            return; 
          }
          let idPaddleRoutesToShow = [paddleId, ...this.idPaddlesToKeepDrawnOnMap]; 
          this.mainMap.drawPaddleRoutes(idPaddleRoutesToShow,this.paddles);
        }

        const mouseLeaveCallback = () => {
          if (!this.paddlesAreLoaded) {
            return; 
          }
          let idArrayRoutesToKeep = this.idPaddlesToKeepDrawnOnMap; 
          this.mainMap.removeAllRoutes(idArrayRoutesToKeep); 
        }

        const clickCallback = (paddleId) => {
          if (!this.paddlesAreLoaded) {
            return; 
          }
          this.idPaddlesToKeepDrawnOnMap = []; 
          let idPaddleRoutesToShow = [paddleId];          
          this.mainMap.drawPaddleRoutes(idPaddleRoutesToShow,this.paddles,() => {});         
          this.idPaddlesToKeepDrawnOnMap.push(paddleId); 
        } 

        this.mainMap.addMapMarkers(this.filteredPaddles, clickCallback, mouseEnterCallback,mouseLeaveCallback, this.attachMoreInfoClickHandler, this.removeMoreInfoClickHandler); 
    },
    flagSearchSelected() {
      this.searchBoxSearchInProgress = true; 
    },
    async initializeDefaultSearchBox() {
      let strLastSearchedLocation = localStorage.getItem('lastSearchedLocation'); 
      let lastSearchedLocation; 
    
      if (strLastSearchedLocation) {
        lastSearchedLocation = JSON.parse(strLastSearchedLocation); 
      }

      this.searchBoxRef = await this.mainMap.addSearchBox(); 
      
      this.searchBoxRef.addEventListener('retrieve',this.flagSearchSelected);

      const searchBoxInput = document.querySelector('.mapboxgl-ctrl input'); 
      if(lastSearchedLocation && lastSearchedLocation.name) {
        searchBoxInput.value = lastSearchedLocation.name; 
      } else {
        this.addSearchBoxPlaceholder(searchBoxInput); 
      }
      document.querySelector('mapbox-search-box').addEventListener('retrieve',(event) => {
        this.saveMostRecentMapboxSearch(event);  
      });
    },
    saveMostRecentMapboxSearch(event) {
      let locationDetail = event.detail.features[0]; 
      if(locationDetail) {
        let geometry = locationDetail.geometry; 
        let coordinates = { lat: geometry.coordinates[1], lng: geometry.coordinates[0]}; 
        let name = ''; 
        if (locationDetail.properties && locationDetail.properties.name) {
          name = locationDetail.properties.name; 
        }
        localStorage.setItem('lastSearchedLocation',JSON.stringify({coordinates: coordinates, name: name})); 
      }
    },
    useCurrentLocation(mapAlreadyInitialized = false) {

      this.isGettingLocation = true;
      
      const options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      };

      const success = (pos) => {
        this.promptForLocation = false; 
      

        const coords = pos.coords;

        let {latitude, longitude} = coords;
        let adjustedCoords = { lat:latitude, lng:longitude};

        this.isGettingLocation = false;
        this.showLocationDeniedTooltip = false; 
        localStorage.setItem('lastSearchedLocation',JSON.stringify({coordinates: adjustedCoords, name: ''})); 

        this.mainMap.centerOnLocation(adjustedCoords);
        this.fetchPaddlesInBounds(); 
  
        if (!mapAlreadyInitialized) {
          this.mainMap.addMapDragEndListener(this.debouncedFetchPaddlesInBounds()); 
          this.initializeDefaultSearchBox(); 
        }
      }

      const error = (err) => {
        this.isGettingLocation = false;
        console.warn(`ERROR(${err.code}): ${err.message}`);
        if (err.code == 1) {
          this.showLocationDeniedTooltip = true; 
        }
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    addSearchBoxPlaceholder(element) {

      element.setAttribute("placeholder","Search for Place or Address"); 
    },
  },
  computed: {
    filteredPaddles() {

      let paddleTypeFilterNothingSelected = this.paddleType.length == 0; 
      let distanceFilterNothingSelected = !this.distance; 

      if (paddleTypeFilterNothingSelected && distanceFilterNothingSelected) {
        return this.paddles;
      } else {
        let filtered = this.paddles.filter((p) => {
          let paddleTypeMatch = false; 
          this.paddleType.forEach((paddleTypeFilterSelection) => {
            if (p.paddleType.includes(paddleTypeFilterSelection)) {
              paddleTypeMatch = true; 
            }
          }); 

          let distanceMatch = false; 
          if (
            (p.distance < 5 && this.distance == 1) || 
            (p.distance >= 5 && p.distance <= 10 && this.distance == 2) ||
            (p.distance > 10 && this.distance == 3)
          ) {
            distanceMatch = true; 
          }

          if (
            (paddleTypeFilterNothingSelected && !distanceFilterNothingSelected && distanceMatch) ||
            (!paddleTypeFilterNothingSelected && paddleTypeMatch && distanceFilterNothingSelected) ||
            (!distanceFilterNothingSelected && distanceMatch && !paddleTypeFilterNothingSelected && paddleTypeMatch)
          ) {
            return true; 
          } else {
            return false;
          }
        }); 
        return filtered; 
      }  
    }
  },
  watch: {
    filteredPaddles(newVal,oldVal) {
      this.addMapMarkers(); 
    },
  },
  data: () => ({
    controller: null,
    showLocationDeniedTooltip: false,
    mobileExpanded: false, 
    searchBoxSearchInProgress: false, 
    searchBoxRef: null,
    isGettingLocation: false,
    mainMap: null, 
    user: null,
    promptForLocation: false,
    mapboxApiKey: process.env.VUE_APP_API_KEY,
    paddleListDrawerHideShow: false, 
    paddlesAreLoaded: false, 
    singlePaddleLoaded: false, 
    paddles: [],
    idPaddlesToKeepDrawnOnMap: [],
    moreInfoClickHandler: () => {}, 
    infoHandlerAddedForPaddle: [],
    showHideSinglePaddleView: false,
    thePaddleToShow: null, 
    prevMapPosition: {},
    returningOutOfSinglePaddleView: false,
    moreInfoLink: [],
    paddlesFetchedForZoomLevel: null,
    paddleType: [],
    paddleTypes: [
      { title: 'Bayou', value: constants.cPaddleTypes.BAYOU },
      { title: 'River', value: constants.cPaddleTypes.RIVER },
      { title: 'Ocean', value: constants.cPaddleTypes.OCEAN },
      { title: 'Lake', value: constants.cPaddleTypes.LAKE },
      { title: 'Flatwater', value: constants.cPaddleTypes.FLATWATER },
      { title: 'Whitewater', value: constants.cPaddleTypes.WHITEWATER } 
    ], 
    distance: null,
    distanceRanges: [
      { title: 'Short (< 5 miles)', value: 1 },
      { title: 'Medium (5 - 10 miles)', value: 2 },
      { title: 'Long (10+ miles)', value: 3 },
    ],
    overlay:false,
    showInfoPopup: false
  }),
}
</script>

<style lang="scss">
  .info-popup {
    position: absolute;
    z-index: 30000000;
    top: 100px;
    padding: 10px;
    right: 10px;
    width: 340px;
    background-color: rgb(var(--v-theme-secondary)); 
    a {
      color: rgb(var(--v-theme-primary)); 
      text-decoration:underline; 
      cursor:pointer; 
    }
    .v-icon {
      position: absolute;
      right: 10px;
      top: 12px;
      font-size: 20px;
    }
  }
  html.is-showing-single-paddle-view {
    overflow:hidden; 
  }
  .is-showing-single-paddle-view .main-map-filter {
    display:none; 
  } 
  button.my-ctrl, button.my-ctrl {
      background-color: #FFFBFF!important;
      min-width:28px!important;
      .v-icon {
        font-size: 20px;   
      }
  }
  button.gen-pdf {
    position:absolute; 
    right:10px; 
    top:55px; 
    z-index:2; 
  }
  .zoom-btns {
    position: absolute;
    bottom: 40px;
    right: 10px;
    display: flex;
    z-index:2;
    flex-direction: column;
    .v-btn:first-child {
      margin-bottom:5px; 
    }
  }
  .paddle-details {
    background-color:white; 
    position: absolute;
    z-index:2000; 
    overflow:scroll; 
    display:none;
  }
  .is-mobile {
    .paddle-details {
      width:100%;  
      bottom: 0;
      transform: translateY(100%);
      height: var(--mobile-single-paddle-height); 
    }
    .paddle-details.show {
      display:block;
      // transition: all 0.2s; 
      transform: translateY(0%); 
    }
    .paddle-details.show.expanded {
      height:100vh; 
      transition: height 0.2s; 
    }
    #map.shrink-map-for-single-paddle-view {
      height: calc(100vh - 45px - var(--mobile-single-paddle-height));  //45px top margin makes way for the header. 
      input {
        display:none; 
      }
      &+.zoom-btns {
        // bottom: calc(var(--mobile-single-paddle-height) + 10px);
      }
    }
  }
  .is-not-mobile {
    .paddle-details {
      box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.5); ; 
      width: var(--not-mobile-single-paddle-width); 
      transform:translateX(100%); 
      height: 100vh; 
      right:0px; 
      top: 0px;
    }
    .paddle-details.show {
      display:block;
      // transition: transform 0.5s; 
      transform: translateX(0%);
    }
    #map.shrink-map-for-single-paddle-view {
      width: calc(100% - var(--not-mobile-single-paddle-width)); 
      input {
        display:none;
      } 

      ~button.gen-pdf {
        right: calc(100% - var(--not-mobile-single-paddle-width) + 7px);
        &+.zoom-btns {
          right: calc(100% - var(--not-mobile-single-paddle-width) + 7px);
        }
      } 
    }
  }
  #map {
    position:absolute;
    top:45px;bottom:0;right:0;left:0;
    overflow:hidden; 
  }
  #map.shrink-map-for-single-paddle-view ~ .main-map-filter {
    display:none; 
  }
  #map.a4-size {
    width:446px!important; //this is the pixel width and height of an a4 page
    height:631px!important; 
  }
  .main-map-overlay {
    .v-overlay__content {
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
    .v-overlay__scrim {
      opacity:0.5; 
    }
  } 
  .paddles-loading-overlay {
    pointer-events:none;
    z-index:2000;  
    position:fixed; 
    left:0; 
    right:0; 
    top:0; 
    bottom:0; 
    .paddles-loading-spinner {
      left:50%; 
      top: 50%; 
      margin-left:-30px; 
      margin-top:-30px; 
    }
  }
  .prompt-location-search-box {
    width: 500px!important; 
    max-width:100%; 
    input {
      font-size: 25px; 
      border-radius: 50px; 
      width: 500px!important; 
      max-width:100%; 
    }  
  }
  .prompt-for-location-container {
    padding:30px; 
    background-color:rgba(255,255,255,0.8); 
    top: 30%; 
    left: 50%; 
    transform:translate(-50%,0); 
    position:absolute; 
    display:flex; 
    flex-direction:column; 
    align-items:center; 
  } 
  .marker {
    background-size: cover;
    width: 20px;
    height: 32px;
    cursor: pointer;
    margin-left:-10px;
    margin-top:4px;
    background-image: url('../assets/marker-icon-orange.png'); 
  }
  .markers-not-ready .marker {
    opacity:0.3; 
    pointer-events:none; 
  }
  .mapboxgl-popup.mapboxgl-popup-anchor-bottom {
    min-width:190px; 
  }
  .marker-popup-image {
    padding-top: 60%;
    margin-top: -10px;
    margin-left: -10px;
    margin-right: -10px;
    background-size: cover;
    background-position: center center;
  }
  .mapboxgl-popup-close-button {
    font-size: 25px;
    padding-right: 5px;
  }
  .mapboxgl-popup-content h1 {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .mapboxgl-popup-content p {
    font-size:15px; 
    margin-bottom:5px; 
  }
  .more-info-text {
    display:block; 
    text-align:right;
    font-weight:bold; 
    color: rgb(var(--v-theme-orangehighlight)); 
    cursor:pointer; 
  }
  .main-map-filter {
    span {
      font-size:14px; 
    }
    position: absolute;
    z-index: 2;
    width: 150px;
    background-color: white;
    border-radius: 5px;
    .v-field {
      padding-right:0px; 
    }
    .v-field__field {
      height:30px; 
    }
    .v-field__input {
      height: 30px;
      padding-top: 0px;
      min-height: 0px;
    }
    .v-field__clearable {
      margin-right:0px;
      i {
        color:rgb(var(--v-theme-primary)); 
        font-size:18px; 
      }
    }
    .v-field__append-inner {
      margin-left:0px; 
      i {
        margin-left:0px;
      }
    }
  }
  .v-select.main-map-filter .v-select__selection-text {
    text-overflow:unset!important; 
  }
  .filter-paddle-type {
    top:55px; 
    left:10px; 
  }
  .filter-distance {
    left:170px; 
    top:55px; 
  }
  .is-mobile .main-map-filter {
    top:100px; 
    width:130px; 
    &.filter-distance {
      left: unset; 
      right:10px; 
    }
    &.filter-paddle-type {
      left:unset; 
      right:150px; 
    }
  }
  .location-denied-hint {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-bottom: 10px;
    width: 190px;
    margin-top: 5px; 
  }

  .v-btn.regular-search-get-current-location-btn {
    position: absolute;
    right: 315px;
    top: 55px;
    min-width: 35px!important;
    padding: 0px 5px;
    height: 30px !important;
  }

</style>