<template>
    <v-overlay v-bind="$attrs" >
      <v-progress-circular
        indeterminate
        color="accent"
        :size="60"
      ></v-progress-circular>
    </v-overlay>
</template>
  
<script>



export default {
    props: {
        
    },
    data: () => ({
       
    }),
    emits: [],
    methods: {
      
    }
}
</script>

<style lang="scss" scoped>
    :deep(.v-overlay__content) {
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
</style>
  