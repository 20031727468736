<template>
  <v-card class="add-edit-paddle">
    <spinner-overlay :model-value="overlay" :persistent="true"></spinner-overlay>
    <v-snackbar
      v-model="snackbarShow"
      :timeout="1800"
      absolute
      bottom
      color="success"
    >
      <strong>Updated</strong>
    </v-snackbar>
    <div class="top-btns">
      <v-btn class="back-to-route-btn" variant="flat" plain color="accent" @click="close()">
        <v-icon color="accent">mdi-arrow-left</v-icon>
        Back to Edit Route
      </v-btn>
      <v-btn
        color="#FF4F00"
        class="save-paddle-btn"
        dark
        variant="flat"
        @click="submitPaddle()"
        >
        Save
      </v-btn>  
    </div>
    <span v-if="!isEdit" class="save-btn-hint">Saves to 'My Routes' page. From there it can be edited, deleted, or shared to the public map.</span>
    <v-card-text>
      <v-form ref="paddleAddEditForm">
        <input
          @change="handleImageUpload"
          ref="imageInput" 
          type="file"
          id="imageInput"
          name="imageInput"
          accept=".jpg, .jpeg, .png"
        />
        <template v-if="featuredImage">
          <v-img
            :src="featuredImage.tempUrl"
            cover
            class="bg-grey-lighten-2"
            style="margin-top:10px"
            @load="featuredImageLoaded = true"
            >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-icon color="primary" class="icon--close--small icon--close" @click="deleteImage(featuredImage,0)">mdi-close-circle</v-icon>
          </v-img>   
        </template>
        <div class="image-upload-div" @click="clickHiddenFileInput()" v-else>
          <v-icon v-if="!featuredImageIsLoading" color="white" class="icon--add-image">mdi-plus</v-icon>
          <v-progress-circular v-else
            style="position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);"
            indeterminate
            color="accent"
          ></v-progress-circular>       
        </div>
        <div v-if="!featuredImage" class="v-text-field__details v-messages" style="line-height:1">Upload Images</div>
        <v-row v-if="nonFeaturedImages.length || featuredImage" class="non-featured-image-row">
          <v-col
            v-for="(image,index) in nonFeaturedImages"
            :key="index" 
            class="d-flex child-flex"
            style="padding:5px"
            cols="3"
          >
            <v-img
              :src="image.tempUrl"
              aspect-ratio="1"
              cover
              class="bg-grey-lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                <v-progress-circular
                  indeterminate
                  color="accent"
                ></v-progress-circular>
                </v-row>
              </template>
              <v-icon color="primary" class="icon--close--small icon--close" @click="deleteImage(image,index + 1)">mdi-close-circle</v-icon>
            </v-img>
          </v-col>
          <div v-if="featuredImage && !imageLoading && featuredImageLoaded" class="thumb-placeholder" @click="clickHiddenFileInput()">    
            <v-icon class="icon--add-image" color="white">mdi-plus</v-icon>         
          </div>
          <div v-if="featuredImage && imageLoading" class="loading-thumb-placeholder thumb-placeholder">    
            <v-progress-circular
              indeterminate
              color="accent"
            ></v-progress-circular>
          </div>
        </v-row>
        <v-text-field
          class="name--form"
          v-model="name"
          density="compact"
          outlined
          :rules="nameRules"
          required
        >
          <template v-slot:label>
            Name <span class="required-asterix">*</span>
          </template>
        </v-text-field>
        <v-textarea
          v-model="description"
          density="compact"
          outlined
          rows="4"
        >
          <template v-slot:label>
            Description
          </template>
        </v-textarea>
        <v-text-field
          class="text-field--small distance--form"
          :model-value="milesDistance"
          label="Distance"
          density="compact"
          required
          outlined
          editable="false"
          type="number"
          suffix="miles"
          min="1"
          readonly
        />
        <v-select
          v-model="boatLaunchType"
          density="compact"
          :items="boatLaunchTypes"
          :rules="genericRequiredRule"
          color="accent"
          outlined
          required
        > 
          <template v-slot:label>
            Boat Launch Type <span class="required-asterix">*</span>
          </template>
          <template v-slot:selection="{ item }">
            <v-chip :closable="true">
              <span>{{ item.title }}</span>
            </v-chip>
          </template>
        </v-select>
        <v-textarea
          v-model="boatLaunchDetails"
          density="compact"
          outlined
          rows="1"
          label="Boat launch details"
        >
        </v-textarea>
        <v-select
          v-model="paddleType"
          :items="paddleTypes"
          density="compact"
          multiple
          outlined
          chips
          :rules="genericRequiredRule"
        >
          <template v-slot:label>
            Paddle Type <span class="required-asterix">*</span>
          </template>
        </v-select>
        <v-checkbox-btn
          v-model="isHazardous"
          color="primary"
          style="margin-top:-10px;"
        >
          <template v-slot:label>
            Any hazards?
          </template>
        </v-checkbox-btn>
        <span class="hazards-hint">If there are any potential hazards others should know about check this box.</span>
        <v-textarea
          v-if="isHazardous"
          v-model="hazardDetails"
          density="compact"
          outlined
          rows="2"
          :rules="(isHazardous) ? hazardDetailRules : []"
          label="Describe hazards here"
        ></v-textarea>
        <v-text-field
          class="outfitter-name"
          v-model="outfitterName"
          density="compact"
          outlined
        >
          <template v-slot:label>
            Outfitter
          </template>
        </v-text-field>
        <span class="hazards-hint">Enter an outfitter or tour/rental company if any.</span>
        <v-text-field
          class="outfitter-website"
          v-model="outfitterWebsite"
          density="compact"
          outlined
        >
          <template v-slot:label>
            Outfitter Website
          </template>
        </v-text-field>
     

        <!-- <v-text-field
          v-model="tags"
          label="Tags"
          outlined
          hint="Enter as comma seperated"
          persistent-hint
          density="compact"
        /> -->
      </v-form>
    </v-card-text>
    <!-- <div class="bottom-btns">
      <v-btn
          color="#FF4F00"
          class="save-paddle-btn"
          dark
          depressed
          @click="submitPaddle()"
          >
          Save
      </v-btn>
    </div> -->
  </v-card>
</template>

<script>

  import {MainMap} from '../utils/mainMap';
  import NODE_API from '../utils/api';
  import constants from '../utils/constants'; 
  import { Auth } from 'aws-amplify';
import { nextTick } from 'vue';
import SpinnerOverlay from '../components/SpinnerOverlay.vue';

  export default {
    name: 'AddEditPaddle',
    components: {
      SpinnerOverlay
    },
    props: {
      userIsLoggedIn: Boolean,
      userId: String,
      paddle: Object,
      milesDistance: String, 
      coordinates: Array, 
      points: Array, 
      isEdit: Boolean
    },
    async mounted() {
      if (this.paddle) {
        let {
          paddleName,
          description,
          launchType,
          launchDetails,
          paddleType,
          images,
          isHazardous, 
          hazardDetails,
          outfitter,
          outfitterWebsite
        } = this.paddle; 
        images = images || []; 
        this.name = paddleName; 
        this.description = description; 
        this.boatLaunchType = launchType; 
        this.boatLaunchDetails = launchDetails
        this.paddleType = paddleType; 
        this.isHazardous = isHazardous; 
        this.hazardDetails = hazardDetails; 
        this.outfitterName = outfitter; 
        this.outfitterWebsite = outfitterWebsite; 
        //format to ui 
        if (images.length > 1) {
          images.sort((a,b) => {
            if (a.order > b.order) {
              return 1; 
            } else {
              return -1; 
            }
          }); 
        }
        this.images = images.map((image) => {
          return {...image, tempUrl: `${constants.imgBaseUrl}${image.url}`}; 
        });  
        this.imagesBefore = Object.assign([],this.images); 
      }
    },
    methods: { 
      clickHiddenFileInput() {
        this.$refs.imageInput.click(); 
      }, 
      async deleteImage(imageToDelete,index) {
        if (!this.isEdit) {
          let body = JSON.stringify({
            type: 'deleteObject', 
            filePath: imageToDelete.filePath
          }); 

          try { 
            let signedUrlResponse = await NODE_API.post(`${constants.amazonGatewayUrl}/getSignedUrl`, body, {
              headers: {
                'Content-Type': 'application/json'
              }
            }); 

            let deleteUrl = signedUrlResponse.data.uploadUrl; 
            let deleteResponse = await NODE_API.delete(deleteUrl); 
            //assuming it all went well if we get to this point. status code is 204 but not sure if it always will be
            //remove image from the frontend 
            this.images.splice(index, 1);
          } catch (e) {
            console.error('Unable to delete image',e); 
          }
        } else {
          //is edit. don't actually delete the image yet. do that on save.
          this.images.splice(index, 1); 
        }
      },
      async getResizedCanvas(file) {
        return new Promise((resolve) => {
          const img = document.createElement('img'); 
         //define event listener before adding image src 
          img.addEventListener('load', () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            
            const maxWidth = 1200;
            const isLandscape = img.width >= img.height; 

            const maxHeight = 800; 

            if (isLandscape) {
              if (img.width <= maxWidth) {
                //dont change size at all 
                canvas.width = img.width; 
                canvas.height = img.height; 

                ctx.clearRect(0,0,canvas.width, canvas.height); 
                ctx.drawImage(img, 0,0, img.width, img.height); 
              } else {
                //scale so width is 1200px 
                canvas.width = maxWidth; 
                let wRatio = maxWidth / img.width; 
                canvas.height = img.height * wRatio; 

                ctx.clearRect(0,0,canvas.width, canvas.height);   
                ctx.drawImage(img, 0,0, img.width, img.height,
                0,0,canvas.width, canvas.height);  
              }
            } else {
              if (img.height <= maxHeight) {
                  //dont change size at all. 
                canvas.width = img.width; 
                canvas.height = img.height; 

                ctx.clearRect(0,0,canvas.width, canvas.height); 
                ctx.drawImage(img, 0,0, img.width, img.height); 
              } else {
                //scale so height is 800px 
                canvas.height = maxHeight; 
                let hRatio = maxHeight / img.height; 
                canvas.width = img.width * hRatio; 

                ctx.clearRect(0,0,canvas.width, canvas.height);   
                ctx.drawImage(img, 0,0, img.width, img.height,
                0,0,canvas.width, canvas.height);  
              }
            }
            resolve(canvas); 
          }); 
          //set the src. this will cause the event listener to fire. 
          img.src = URL.createObjectURL(file); 
        });   
      }, 
      async handleImageUpload() {
        if(this.$refs.imageInput.files.length == 0) {
          return;
        }
        //get file from input. 
        let file = this.$refs.imageInput.files[0]; 

        this.imageLoading = true; 
        //get its extension
        const matches = file.name.match(/\.([a-zA-Z0-9]+)$/)
        const extension = (matches) ? matches[1] : 'txt'; 
        //get signed URL to upload to Amazon bucket 
        let body = JSON.stringify({
          type: 'putObject', 
          extension,
          mime: file.type || 'application/octet-stream',
        }); 
        try {
          let signedUrlResponse = await NODE_API.post(`${constants.amazonGatewayUrl}/getSignedUrl`, body,{
            headers: {
              'Content-Type': 'application/json'
            }
          }); 
          let {uploadUrl, filePath} = signedUrlResponse.data; 
          
          let canvas = await this.getResizedCanvas(file); 
          //upload the resized file 
          let blob = canvas.toBlob(async (blob) => {
            let options = {
              headers: {
                'Content-Type': file.type
              }
            };

            file = new File([blob], file.name);
            //got the signed url. Now actually upload to S3 bucket. 
            let uploadResponse = await NODE_API.put(uploadUrl, file, options); 
            //include location in S3 bucket
            file.filePath = filePath; 
            //show resized image ion front end without having to GET 
            file.tempUrl = canvas.toDataURL(); 
  
            this.images.push(file); 
            //hide the loading icon 
            this.imageLoading = false; 
          }, file.type);     
        } catch (e) {
          console.error('Could not upload image',e); 
        }    
      },
      close() {
        this.$emit('close',true);
      },
      async checkFormIsValid() {
        
          let isValid = await this.$refs.paddleAddEditForm.validate();
          return isValid.valid; 
      },
      async submitPaddle() {
        let isValid = await this.checkFormIsValid(); 
    
        if (!isValid) {
          return; 
        }
        
       
        try {
          this.user = await Auth.currentAuthenticatedUser(); 
        } catch(err) {
          this.$router.push('/auth'); 
          return; 
        }
   
        this.overlay = true;

        let formData = {
          name: this.name,
          route: { coordinates: this.coordinates, points: this.points }, 
          distance: this.milesDistance, 
          description: this.description, 
          launchType: this.boatLaunchType, 
          launchDetails: this.boatLaunchDetails, 
          paddleType: this.paddleType,
          location: this.coordinates[0],
          isHazardous: this.isHazardous, 
          hazardDetails: this.hazardDetails,
          outfitter: this.outfitterName,
          outfitterWebsite: this.outfitterWebsite
        }; 
        
        if (this.isEdit) {
          formData.paddleId = this.paddle._id; 
          let imagesToDelete = this.imagesBefore.filter((ib) => !this.images.find((i) => i.url == ib.url)); 
          formData.imagesToDelete = imagesToDelete.map((i) => i.url); 
          //update image order 
          for(let i = 0, length = this.images.length; i < length; i++) {
            this.images[i].order = i + 1; 
          }

          formData.images = this.images; 
          formData.username = this.user.username; 

          let body = JSON.stringify(formData);
         
          try {
            let response = await NODE_API.put(`${constants.amazonGatewayUrl}/paddle`,body, {
              headers: {
                'Content-Type': 'application/json'
              }
            }); 

            this.images = response.data.ret.images.map((image) => {
              return {...image, tempUrl: `${constants.imgBaseUrl}${image.url}`}
            }) 
            this.imagesBefore = Object.assign([],this.images);  
            //indicate success 
            this.snackbarShow = true;
            await nextTick(); 
            this.overlay = false; 
          
          } catch (e) {
            console.error('there was an error',e); 
          }

        } else {
          //add paddle 
          //include temp images paths. We'll move these to perm folder 
          formData.tempImgPaths = this.images.map((f) => f.filePath); 
          //include username for path
          formData.username = this.user.username; 
     
          let body = JSON.stringify(formData);

          try {
            let response = await NODE_API.post(`${constants.amazonGatewayUrl}/paddle`,body, {
              headers: {
                'Content-Type': 'application/json'
              }
            }); 

            const newlyAddedPaddle = response.data.ret; 
  
            setTimeout(() => {
              this.$router.push({path:'myroutes'}); 
              this.overlay = false; 
            },2000);//this should be enough time for aws to generate the thumbnail image  
             
          } catch (e) {
            console.error('there was an error',e); 
          }
        
        }
      }
    },
    computed: {
      featuredImageIsLoading() {
        return this.imageLoading && this.images.length == 0; 
      }, 
      fileTwoRules() {
        return [() => (this.fileOne) || 'Upload to file one first']; 
      },
      nonFeaturedImages() {
        if (this.images.length <2) {
          return []; 
        } else {
          return this.images.slice(1); 
        }
      }, 
      featuredImage() {
        if (this.images.length === 0) {
          return null; 
        } else {
          return this.images[0]; 
        }
      }
    },
    data: () => ({
      outfitterWebsite: '',
      outfitterName: '',
      name: '',
      distance: null,
      description: '',
      boatLaunchType: '',
      boatLaunchTypes: ['Car park', 'Boat launch', 'Beach', 'Road shoulder'],
      boatLaunchTypes: [
        { title: 'Car park', value: constants.cBoatLaunchTypes.CARPARK },
        { title: 'Boat launch', value: constants.cBoatLaunchTypes.BOATLAUNCH },
        { title: 'Beach', value: constants.cBoatLaunchTypes.BEACH },
        { title: 'Road shoulder', value: constants.cBoatLaunchTypes.ROADSHOULDER }
      ], 
      boatLaunchDetails: '', 
      tags: '',
      paddleType: [],
      paddleTypes: [
        { title: 'Bayou', value: constants.cPaddleTypes.BAYOU },
        { title: 'River', value: constants.cPaddleTypes.RIVER },
        { title: 'Ocean', value: constants.cPaddleTypes.OCEAN },
        { title: 'Lake', value: constants.cPaddleTypes.LAKE },
        { title: 'Flatwater', value: constants.cPaddleTypes.FLATWATER },
        { title: 'Whitewater', value: constants.cPaddleTypes.WHITEWATER } 
      ], 
      isHazardous: false, 
      hazardDetails: '',
      overlay: false,
      rules: {}, 
      imageLoading: false, 
      featuredImageLoaded: false, 
      nameRules: [
        v => !!v || "Name is required"
      ],
      descriptionRules: [
        v => !!v || "Please enter a description"
      ],
      distanceRules: [
        v => !!v || "Distance is required"
      ],
      hazardDetailRules: [
        v => !!v || "Please provide details"
      ], 
      genericRequiredRule: [
        v => !!v || "Required"
      ],
      images: [],
      options: {
        url: '/upload',
        paramName: 'file'
      },
      user: {},
      snackbarShow: false, 
      imagesBefore: [] 
    }),
    watch: {
      imagesBefore(newVal,oldVal) {
        // console.log('new value',newVal); 
        // console.log('oldVal',oldVal); 
      },
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.v-label .required-asterix) {
    color:rgb(var(--v-theme-error));
  }
  .v-text-field :deep(.v-input__details) {
    padding:0px; 
    min-height:0px; 
    padding-inline:0px; 
  }
  .v-card .icon--close {
    position: absolute;
    right: 10px;
    cursor:pointer;
    z-index:5
  }
  .v-card-text {
    padding:0px 10px; 
    padding-bottom:10px; 
  }
  .v-card .icon--close.icon--close--small {
    right:0px; 
    font-size:20px!important; 
  }
  .v-icon.icon--add-image {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;font-size:30px; 
  }
  .text-field--small {
    width:200px;
  }
  ::v-deep .v-text-field__suffix {
    font-size:12px;
  }
  ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom:0px;
    min-height:0px;
    padding:0px; 
    .v-messages {
      min-height:0px; 
    }
  }
  .v-expansion-panel-header, ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding:0px; 
    min-height:30px; 
  }

  ::v-deep .v-input.error--text {
    margin-bottom:0px!important;
    .v-text-field__details{
      margin-top:1px;
      display:block;
      padding:0px;
    }
  }

  ::v-deep .v-select.v-select--chips.v-input--dense .v-select__selections {
    min-height:0px; 
  }

  ::v-deep .v-file-input.error--text + .v-file-input
   {
    margin-top:0px!important;
  }
  ::v-deep .v-input__slot {
    margin-bottom:0px!important; 
  }
  .v-expansion-panel-header {
    margin-top:10px; 
    &.v-expansion-panel-header--active {
      margin-top:0px; 
    }
  }
  .name--form {
    margin-top:10px; 
  }
  .add-edit-paddle {
    height:100vh; 
    overflow:scroll; 
  }
  #imageInput{
    opacity:0; 
    display:none; 
  }
  .image-upload-div {
    margin-top: 10px;
    min-height: 50px;
    background-color:lightgray; 
    position:relative; 
    border-radius: 5px; 
    cursor:pointer; 
  }
  .thumb-placeholder {
    position: relative;
    background-color: lightgray;
    border-radius: 5px;
    height:50px; 
    width:50px; 
    cursor:pointer; 
  }
  .loading-thumb-placeholder .v-progress-circular{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 20px!important;
    width: 20px!important;
  }
  .non-featured-image-row {
    margin:5px -5px; 
  }
  .back-to-route-btn,.save-paddle-btn {
    ::v-deep .v-btn__content {
      opacity:1 !important; 
    }
  }
  .back-to-route-btn {
    padding: 0px 16px 0px 12px; 
  }
  .save-paddle-btn {
    margin-right:5px;  
  }
  .top-btns {
    display:flex; 
    justify-content:space-between; 
    padding:10px; 
    align-items:center;
  }
  .v-btn.bg-accent {
    color:white!important; 
    i.v-icon {
      color:white!important; 
    }
  }
  .bottom-btns {
    @extend .top-btns; 
    justify-content:flex-end; 
  }
  :deep(.v-chip__close .v-icon) {
    color:rgb(var(--v-theme-primary)); 
  }
  .hazards-hint {
    display: block;
    margin-top: -7px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 5px;
    margin-left: 12px;
  }
  .outfitter-name {
    padding-top:30px; 
  }
  .save-btn-hint {
    font-size:12px; line-height:12px; color:gray; 
    margin-top: -10px;
    display: block;
    padding: 10px;
    padding-left:20px; 
    width: 100%;
  }
</style>
