//helper functions to draw on map to be able to see whats going on.  
export function showFeatureGeometry(theMap, geometry) {
  //geometry = {type: coordinates: }
  let waterFeatureSource = theMap.getSource('water-feature');
  
  if (typeof waterFeatureSource === 'undefined') {
    
      theMap.addSource('water-feature', {
        'type': 'geojson',
        'data': {
        'type': 'Feature',
        'geometry': geometry
        }
      });
  
      theMap.addLayer({
        'id': 'water-feature-layer',
        'type': 'fill',
        'source': 'water-feature', // reference the data source
        'layout': {},
        'paint': {
        'fill-color': '#00AF54', // blue color fill
        'fill-opacity': 0.5
        }
      });

      theMap.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'water-feature',
        'layout': {},
        'paint': {
        'line-color': '#000',
        'line-width': 1
        }
      });
    
  } else {
      // //update the source
      waterFeatureSource.setData({
        'type': 'Feature',
        'geometry': geometry
      });
  }
}

export function showPoints(theMap, geoJsonPoints, id) {
  let intersectPointsSrc = theMap.getSource('intersectpoints'); 
  if (typeof intersectPointsSrc === 'undefined') {
      theMap.addSource('intersectpoints', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': geoJsonPoints
        }
      });
      //initialize layer
      theMap.addLayer({
        'id': 'intersect-points-layer',
        'type': 'circle',
        'source': 'intersectpoints',
        'paint': {
        }
      });
  } else {
    intersectPointsSrc.setData({
      "type": "FeatureCollection",
      "features": geoJsonPoints 
    });
  }
}


export function showLineString(theMap, geojsonLineString, id) {

  let intersectPoints = theMap.getSource('intersectptsline'); 
    //no line drawn yet so add the source and the layer
  if (typeof intersectPoints === 'undefined') {
    //no line drawn yet so add the source and the layer
    theMap.addSource('intersectptsline', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': [geojsonLineString]
        }
    });

    theMap.addLayer({
      'id': 'intersectpts-line-layer',
      'type': 'line',
      'source': 'intersectptsline',
      'paint': {
      'line-width': 3,
      'line-color': 'yellow'
      }
    }); 
    } else {
      //update existing source
       intersectPoints.setData({
        "type": "FeatureCollection",
        "features": [geojsonLineString] 
      });
    }
  }

  export function deleteAllSourceAndLayers(theMap) {
    if (theMap.getLayer('water-feature-layer')) {
      theMap.removeLayer('water-feature-layer'); 
    } 
    if (theMap.getLayer('outline')) {
      theMap.removeLayer('outline'); 
    } 
    if (theMap.getSource('water-feature')) {
      theMap.removeSource('water-feature'); 
    }
    if (theMap.getLayer('intersect-points-layer')) {
      theMap.removeLayer('intersect-points-layer'); 
    }
    if (theMap.getSource('intersectpoints')) {
      theMap.removeSource('intersectpoints'); 
    }
    if (theMap.getLayer('intersectpts-line-layer')) {
      theMap.removeLayer('intersectpts-line-layer'); 
    }
    if (theMap.getSource('intersectptsline')) {
      theMap.removeSource('intersectptsline'); 
    }

  }